// extracted by mini-css-extract-plugin
export var previewTitle = "article-preview-module--previewTitle--295gz";
export var preview = "article-preview-module--preview--3AoZO";
export var headBlock = "article-preview-module--headBlock--1BM8s";
export var imgItem = "article-preview-module--imgItem--2z7Fq";
export var imgItemInner = "article-preview-module--imgItemInner--z6ox8";
export var leftItem = "article-preview-module--leftItem--1aT8-";
export var rightItem = "article-preview-module--rightItem--35Sqt";
export var imgSideItemSpacer = "article-preview-module--imgSideItemSpacer--2S0Jt";
export var triangleMark = "article-preview-module--triangleMark--2pFLe";
export var tag = "article-preview-module--tag---JlLV";